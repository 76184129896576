.p-creating-account {

  & {
    --page-container-background: var(--color-branding-support-1);
    --page-background: var(--color-branding-support-1);
  }

  .c-title {
    color: var(--color-white);
  }
}