.c-form-group {
  & {
    font-family: var(--font-family);
    font-size: var(--font-size);
    color: var(--color-body);
    padding-bottom: var(--space-sm);
  }


  &__label {
    display: block;
    margin: var(--space-xs) 0;

  }

  &__has-error {

  }

  &__error {
    margin: var(--space-xxs) 0;
    color: var(--color-negative);
  }

}