.c-checkbox {
  outline: none;

  &__input {
    position: absolute;
    -webkit-appearance: none;
    height: 1px;
    opacity: 0;
    width: 1px;
  }

  &__label-text {
    margin-left: 8px;
    user-select: none;
    text-align: left;
  }

  &.type-regular {
    .c-checkbox__label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .c-checkbox__icon-container {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      background-color: var(--color-white);
      border: 1px solid var(--color-border);
      border-radius: var(--border-radius-xxs);
      cursor: pointer;
    }

    &.hasError {
      .c-checkbox__icon-container {
        border: 1px solid var(--color-negative);
      }
    }

    .c-checkbox__icon {
      display: block;
    }

    &.is-focused {
      .c-checkbox__icon-container {
        border-color: var(--color-primary);
      }
    }

    &.is-checked {
      .c-checkbox__icon {
        width: 16px;
        height: 16px;
        background: url('../svg/check-icon.svg') no-repeat center;
        background-size: contain;
      }
    }

    &.is-disabled {
      .c-checkbox__icon-container,
      .c-checkbox__label-text {
        opacity: 0.6;

        cursor: not-allowed;
      }
    }

    &.is-disabled.show-cross-when-disabled {
      .c-checkbox__icon {
        width: 10px;
        height: 10px;
        background: url('../svg/check-cross-icon.svg') no-repeat center;
        background-size: contain;
        opacity: 0.6;
      }
    }
  }

  &.type-switch {
    .c-checkbox__label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      width: 44px;
      height: 25px;
      position: relative;
    }

    .c-checkbox__icon-container {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-helper-1);
      border-radius: 16px;

      &:after {
        position: relative;
        display: block;
        content: "";
        height: 21px;
        width: 21px;
        top: 2px;
        left: 2px;
        background-color: white;
        border-radius: 50%;
        transition: all .2s ease;
      }
    }


    &.is-checked {
      .c-checkbox__icon-container {
        background-color: var(--color-positive);

        &:after {
          left: 21px;
        }
      }
    }

    &.is-disabled {
      .c-checkbox__icon-container,
      .c-checkbox__label-text {
        opacity: 0.6;

        cursor: not-allowed;
      }
    }

    .c-checkbox__label-text {
      display: none;
    }
  }
}
