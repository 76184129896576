//@import 'spacing';
@import 'typography';
@import 'colors';
@import 'spacing';
@import 'utils';

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  color: var(--color-body);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

body {
  background-color: var(--color-white);
  color: var(--color-body);
  margin: 0;
  // overscroll-behavior: none;
  // overflow: hidden;
}

body,
.app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html {
  font-family: var(--font-family);
  line-height: 1.31;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}