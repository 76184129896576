.p-postal-code {
  & {
  }

  &__input {
    & {
      -moz-appearance: textfield;
      text-align: center;
      margin: 0 auto;
      padding: var(--space-md) var(--space-xs);
      max-width: 96px;
      display: block;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}