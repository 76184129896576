.c-select {
  & {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.76246 0.570268L9.28117 0.188342C9.11438 0.0627923 8.91958 0 8.69722 0C8.47027 0 8.27779 0.0627923 8.11946 0.188342L5.00002 2.66298L1.88061 0.188413C1.7223 0.0628639 1.52977 7.15256e-05 1.30292 7.15256e-05C1.08044 7.15256e-05 0.885688 0.0628639 0.718878 0.188413L0.243897 0.57034C0.0812691 0.69926 0 0.853753 0 1.03368C0 1.21695 0.0813591 1.3697 0.243875 1.49188L4.4223 4.80654C4.57641 4.93548 4.76887 5 5 5C5.22679 5 5.42152 4.93549 5.58399 4.80654L9.76244 1.49188C9.92078 1.36626 10 1.21353 10 1.03368C10 0.857106 9.9208 0.702701 9.76246 0.570268Z' fill='%23009FE3'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-position-x: calc(100% - 10px);
    background-repeat: no-repeat;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-xs, 8px);
    color: var(--color-body);
    font-size: var(--font-size);
    font-weight: var(--font-weight-normal);
    padding: var(--space-select-padding); // 0 16px 1px 8px;
    position: relative;
    width: 100%;
  }

  &:focus {
    color: var(--color-primary);
    border-color: var(--color-primary);
    outline: none;
  }

  &--disabled,
  &:disabled {
    cursor: not-allowed;
    color: var(--color-text-lighter);
    background-color: var(--color-chrome);
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.76246 0.570268L9.28117 0.188342C9.11438 0.0627923 8.91958 0 8.69722 0C8.47027 0 8.27779 0.0627923 8.11946 0.188342L5.00002 2.66298L1.88061 0.188413C1.7223 0.0628639 1.52977 7.15256e-05 1.30292 7.15256e-05C1.08044 7.15256e-05 0.885688 0.0628639 0.718878 0.188413L0.243897 0.57034C0.0812691 0.69926 0 0.853753 0 1.03368C0 1.21695 0.0813591 1.3697 0.243875 1.49188L4.4223 4.80654C4.57641 4.93548 4.76887 5 5 5C5.22679 5 5.42152 4.93549 5.58399 4.80654L9.76244 1.49188C9.92078 1.36626 10 1.21353 10 1.03368C10 0.857106 9.9208 0.702701 9.76246 0.570268Z' fill='%239F9F9F'/%3E%3C/svg%3E%0A");
  }

  &--error {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.76246 0.570268L9.28117 0.188342C9.11438 0.0627923 8.91958 0 8.69722 0C8.47027 0 8.27779 0.0627923 8.11946 0.188342L5.00002 2.66298L1.88061 0.188413C1.7223 0.0628639 1.52977 7.15256e-05 1.30292 7.15256e-05C1.08044 7.15256e-05 0.885688 0.0628639 0.718878 0.188413L0.243897 0.57034C0.0812691 0.69926 0 0.853753 0 1.03368C0 1.21695 0.0813591 1.3697 0.243875 1.49188L4.4223 4.80654C4.57641 4.93548 4.76887 5 5 5C5.22679 5 5.42152 4.93549 5.58399 4.80654L9.76244 1.49188C9.92078 1.36626 10 1.21353 10 1.03368C10 0.857106 9.9208 0.702701 9.76246 0.570268Z' fill='%23FF6266'/%3E%3C/svg%3E%0A");
    border-color: var(--color-negative);
    color: var(--color-negative);
  }
}