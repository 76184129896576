.c-radio {
  & {
    position: relative;
    outline: none;
  }

  input {
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    top: .25rem;
    left: 0;
    height: 24px;
    width: 24px;
    overflow: hidden;
  }

  input, label {
    touch-action: manipulation;
  }

  input {
    overflow: visible;
  }

  label {
    & {
      position: relative;
      min-height: 24px;
      margin-top: 0;
      cursor: pointer;
      line-height: 24px;
      color: var(--color-body);
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    > span {
      margin-left: 30px;
      user-select: none;
      text-align: left;
    }
  }

  input[type=radio]:checked + label:before {
    background-color: var(--color-secondary);
    box-shadow: inset 0 0 0 6px #fff;
    border-radius: 50%;
  }

  input[type=radio] + label:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: inline-block;
    height: 24px;
    width: 24px;
    border: 1px solid #b3b3b3;
    border-radius: 50%;
  }

  &.is-focused {
    input[type=radio]:checked + label:before {
      background-color: var(--color-primary);
    }
  }

  &.is-disabled {
    label,
    input[type=radio]:checked + label:before {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
