.c-list-item-button {
  & {
    padding: 0 var(--space-unit);
  }

  .c-button {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
    text-transform: uppercase;
    font-size: var(--font-size-sm);
  }
}