.c-box {
  & {
    border-radius: var(--border-radius-xs);
    padding: var(--space-unit);
    font-family: var(--font-family);
  }

  &__appearance-default {
    background: var(--color-chrome);
    color: var(--color-body);
  }

  &__appearance-red {
    background: var(--color-negative-support);
    color: var(--color-body);
  }

}