.c-input {
  & {
    outline: none;
    background-color: transparent;
    color: var(--color-body);
    font-family: var(--font-family);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-xs, 8px);
    padding: var(--space-input-padding);
    caret-color: var(--color-primary-emphasis);
    position: relative;
    width: 100%;
  }

  &::placeholder {
    color: var(--color-text-lighter);
  }

  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: var(--color-text-lighter) !important;
  }

  &:focus {
    color: var(--color-primary);
    border-color: var(--color-primary);
    outline: none;
  }

  &--disabled,
  &:disabled {
    cursor: not-allowed;
    color: var(--color-text-lighter);
    background-color: var(--color-chrome);
  }

  &--error {
    border-color: var(--color-negative);
    color: var(--color-negative);
  }
}
