.c-list-item-text {
  & {
    padding: var(--space-sm);
    flex: 1 1 auto;
  }

  &__primary {
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: bold;
    color: var(--color-body);
    line-height: 21px;
  }

  &__secondary {
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: normal;
    color: var(--color-body);
  }
}