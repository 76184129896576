@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i');

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: bold;
  src: url('./../fonts/avenir/3A0AF8_0_0.eot');
  src: url('./../fonts/avenir/3A0AF8_0_0.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/avenir/3A0AF8_0_0.woff2') format('woff2'),
  url('./../fonts/avenir/3A0AF8_0_0.woff') format('woff'),
  url('./../fonts/avenir/3A0AF8_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: normal;
  src: url('./../fonts/avenir/3A0AF8_1_0.eot');
  src: url('./../fonts/avenir/3A0AF8_1_0.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/avenir/3A0AF8_1_0.woff2') format('woff2'),
  url('./../fonts/avenir/3A0AF8_1_0.woff') format('woff'),
  url('./../fonts/avenir/3A0AF8_1_0.ttf') format('truetype');
}

:root {
  --font-size-unit: 1em;
  --font-size-xxs: calc(0.25 * var(--font-size-unit));
  --font-size-xs: calc(0.5 * var(--font-size-unit));
  --font-size-sm: calc(0.75 * var(--font-size-unit));
  --font-size: var(--font-size-unit);
  --font-size-md: calc(1.25 * var(--font-size-unit));
  --font-size-lg: calc(1.75 * var(--font-size-unit));
  --font-size-xl: calc(2.25 * var(--font-size-unit));
  --font-size-xxl: calc(2.25 * var(--font-size-unit));

  --font-family: 'Roboto', sans-serif;
  --font-family-title: 'AvenirNextLTPro', 'Roboto', sans-serif;
  --font-family-title-bold: 'AvenirNextLTPro', 'Roboto', sans-serif;

  --font-weight-normal: normal;
  --font-weight-bold: 700;
}
