.c-back-button {
  & {
    color: var(--color-primary);
  }

  &:hover {
    &:not(&:disabled) {
      color: var(--color-primary-emphasis);
    }
  }
}