:root {
  --color-primary: #009FE3;
  --color-secondary: #54585F;
  --color-white: #FFFFFF;
  --color-body: #2D2D2D;

  --color-emphasis-background: #E8F5FC;
  --color-primary-emphasis: #0A78B2;
  --color-primary-light: #2DA8E5;
  --color-luscii-pink: #ff0099;
  --color-luscii-green: #6abfa5;

  --color-positive: #4AD461;
  --color-positive-support: #E7F5EC;
  --color-negative: #FF6266;
  --color-negative-support: #FFF1F1;
  --color-warning: #FFAD62;

  --color-chrome: #F3F3F3;
  --color-divider: #EEEEEE;
  --color-border: #CCCCCC;
  --color-accent: #B9B9B9;
  --color-text-lighter: #9F9F9F;
  --color-pale-grey: #F2FAFD;
  --color-pale-grey-2: #F1F1F1;
  --color-placeholder: var(--color-text-lighter);
  --color-text-secondary: #737373;
  --color-text-emphasis: #0F3554;

  --color-comp-dark: #32918F;
  --color-comp-light: #57BBB9;
  --color-comp-alt: #43BBE7;
  --color-comp-active: #516ED0;
  --color-comp-darker: #46375F;
  --color-comp-darkest: #1F1E1E;

  --color-negative-alt: #FF6660;
  --color-negative-alt-emphasis: #E55B56;
  --color-branding-support-1: #6ABFA5;
  --color-branding-support-2: #6670A5;
  --color-branding-support-4: #0f3554;

  --color-helper-1: #C5D0DE;
  --color-emphasis-2: #f0f8f6;
}