.c-form {

  &__form-group-checkbox {
    padding-top: var(--space-xs);
  }

  &__legal-box,
  &__error-box {
    & {
      margin-top: var(--space-unit);
    }

    h2 {
      margin: 0 0 var(--space-xs);
      font-size: var(--font-size);
    }

  }

  &__footer {
    & {
      margin-top: var(--space-unit);
    }

    .c-button {
      display: block;
      width: 100%;
    }
  }
}