$page-max-width: 540px;

.c-page {

  & {
    --page-container-background: var(--color-white);
    --page-background: var(--color-chrome);
    --page-container-padding-x: var(--space-lg);

    min-height: 100vh;
    background-color: var(--page-background);
    color: var(--color-body);
    padding-bottom: var(--space-xxl);

    @media screen and (max-width: $page-max-width) {
      & {
        --page-container-padding-x: var(--space-unit);
        background-color: var(--page-container-background);
        padding-bottom: 0;
      }

      .c-page__container {
        border-radius: 0;
      }
    }
  }

  &--modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__container {
    border-radius: 8px;
    background-color: var(--page-container-background);
    margin: 0 auto;
    padding: var(--space-md) var(--page-container-padding-x);
    max-width: $page-max-width;
  }

  &__back-button {
    min-height: 69px;
    padding: var(--space-sm);
    position: sticky;
    top: 0;

    @media screen and (max-width: $page-max-width) {
      position: relative;
      min-height: 53px;
      background-color: var(--page-container-background);
      padding: var(--space-xs) var(--space-xs) 0 0;
    }
  }

  &__header {

    & {
      margin: 0 0 var(--space-unit) 0;
      position: relative;
    }

    &__image {

      & {
        position: relative;
        margin-bottom: var(--space-md);
      }

      &:before {
        display: block;
        content: "";
        width: 50%;
        padding-top: calc((1 / 1) * 50%);
      }

      > .inner {
        position: absolute;
        top: 0;
        right: 25%;
        bottom: 0;
        left: 25%;
      }
    }

    &__title {
      & {
        text-align: center;
        padding: var(--space-md) 0;
      }

      @media screen and (max-width: $page-max-width) {
        padding: 0;
      }

      .c-title {
        & {
          max-width: 40vw;
          margin: 0 auto;
        }

        &__full-width {
          max-width: 80vw;
        }
      }
    }
  }

  &__content {

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .c-list {
      padding: 0; //var(--space-md) 0;
    }

    .c-code-input {
      margin: var(--space-sm) 0 0 0;
    }
  }

  &--no-content-padding {
    .c-page__content {
      margin: 0 calc(-1 * var(--page-container-padding-x));
    }
  }
}