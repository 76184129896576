.c-title {
  & {
    color: var(--color-text-emphasis);
    font-family: var(--font-family-title);
    font-size: var(--font-size-lg);
    margin: 0;
  }

  &__type-big {
    font-size: var(--font-size-xl);
  }

  &__type-small {
    font-family: var(--font-family-title-bold);
    font-size: var(--font-size-md);
  }

  &__type-tiny {
    font-family: var(--font-family-title-bold);
    font-size: var(--font-size);
  }

  &__type-bold {
    font-family: var(--font-family-title-bold);
    font-size: var(--font-size-lg);
  }
}