.c-code-input {
  & {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .c-input::-webkit-outer-spin-button,
  .c-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .c-input {
    -moz-appearance: textfield;
    text-align: center;
    flex: 0 0 32px;
    max-width: 32px;
    margin: 0 var(--code-input-margin);
    padding:var(--space-md) var(--space-xs);


    & + span {
      font-size: var(--font-size);
      color: var(--color-text-lighter);
    }
  }

  &__input-filled {
    &:not(.c-input--error) {
      border-color: var(--color-primary);
    }
  }


  &__appearance-default {
    --code-input-margin: var(--space-xs);
  }

  &__appearance-date {
    --code-input-margin: var(--space-xxs);
  }
}
