/**
 * Spacing system
 * Based on the following article: https://medium.com/codyhouse/create-your-design-system-part-4-spacing-895c9213e2b9
 */
:root {

  /*
   Calcs that are based around 1em.
   ------------------------------
   xxs = 3.25px (based on 1em)
   xs  = 6.5px (based on 1em)
   sm  = 9.75px (based on 1em)
   md  = 16.25px (based on 1em)
   lg  = 26px (based on 1em)
   xl  = 42px (based on 1em)
   xxl = 68.25px (based on 1em)

   unit = 16px (based on 1rem)
   xxs = 4px (based on 1rem)
   xs  = 8px (based on 1rem)
   sm  = 12px (based on 1rem)
   md  = 20px (based on 1rem)
   lg  = 32px (based on 1rem)
   xl  = 52px (based on 1rem)
   xxl = 84px (based on 1rem)
  */

  --space-unit: 1rem;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));

  // spacing for specific elements
  --space-component-padding: var(--space-sm);

  // button
  --space-button-padding: var(--space-sm) var(--space-sm);

  // select
  --space-select-padding: var(--space-sm) var(--space-md) var(--space-sm) var(--space-sm);

  // input
  --space-input-padding: var(--space-sm) var(--space-md) var(--space-sm) var(--space-sm);

  // text
  --text-padding: var(--space-xs) var(--space-sm);

  // code input
  --code-input-margin: var(--space-xs);
}



/**
 * Spacing utility class
 */

// margin helpers
.margin-top, .margin-top--md {
  margin-top: var(--space-md);
}

.margin-top--sm {
  margin-top: var(--space-sm);
}

.margin-top--lg {
  margin-top: var(--space-lg);
}

.margin-top--xl {
  margin-top: var(--space-xl);
}

.margin-top--xxl {
  margin-top: var(--space-xxl);
}

.margin-bottom, .margin-bottom--md {
  margin-bottom: var(--space-md);
}

.margin-bottom--sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom--lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom--xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom--xxl {
  margin-bottom: var(--space-xxl);
}

// padding helpers
.padding-top, .padding-top--md {
  padding-top: var(--space-md);
}

.padding-top--sm {
  padding-top: var(--space-sm);
}

.padding-top--lg {
  padding-top: var(--space-lg);
}

.padding-top--xl {
  padding-top: var(--space-xl);
}

.padding-top--xxl {
  padding-top: var(--space-xxl);
}

.padding-bottom, .padding-bottom--md {
  padding-bottom: var(--space-md);
}

.padding-bottom--sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom--lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom--xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom--xxl {
  padding-bottom: var(--space-xxl);
}
