.p-age-verification {
  --code-input-margin: var(--space-xxs);
  .c-form-group__error {
    text-align: center;
  }


  &__input-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  &__input {
    & {
      -moz-appearance: textfield;
      text-align: center;
      margin: 0 var(--code-input-margin);
      padding: var(--space-md) var(--space-xs);
      display: block;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &--day,
    &--month {
      flex: 0 0 64px;
      max-width: 64px;
    }

    &--year {
      flex: 0 0 72px;
      max-width: 72px;
    }
  }

  &__date-separator {
    font-size: var(--font-size);
    color: var(--color-text-lighter);
  }
}