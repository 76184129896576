.c-button {

  & {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-button-padding);
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size);
    min-width: 44px;
  }

  &__role-primary {
    & {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
      color: var(--color-white);
    }

    &:hover, &:active, &:focus {
      border-color: var(--color-primary-emphasis);
      background-color: var(--color-primary-emphasis);
    }
  }

  &__role-secondary {
    & {
      border-color: var(--color-primary);
      background-color: var(--color-white);
      color: var(--color-primary);
    }

    &:hover, &:active, &:focus {
      border-color: var(--color-primary-emphasis);
      color: var(--color-primary-emphasis);
    }
  }

  &__role-destructive {
    & {
      border-color: var(--color-negative-alt);
      color: var(--color-negative-alt);
    }

    &:hover, &:active, &:focus {
      border-color: var(--color-negative-alt-emphasis);
      color: var(--color-negative-alt-emphasis);
    }
  }

  &__role-default {
    // this is the normal button base styles.
  }

  &:disabled,
  &--disabled {
    cursor: not-allowed;
    border: 1px solid var(--color-border);
    background-color: transparent;
    color: var(--color-border);
  }
}
