.p-program-information-page {
  &__image {
    & {
      padding-bottom: var(--space-md);
      text-align: center;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__footer {
    margin-top: var(--space-md);
    .c-button {
      display: block;
      width: 100%;
    }
  }
}
