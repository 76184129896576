.c-list-item {
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--color-chrome);
    min-height: 80px;
    margin-left: var(--space-unit);
    cursor: pointer;
  }

  &:last-child {
    border-color: transparent;
  }
}