// @import '../../styles/colors';
// @import '../../styles/layout';

.c-radio-group {

  & {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }

  > .c-radio {
    flex: 0 0 auto;
    margin-right: var(--space-xs);
  }

  &.vertical {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    > .c-radio {
      flex: 0 0 auto;
      margin-bottom: var(--space-xs);
    }
  }
  &.hasError {
    > .c-radio .c-radio__icon-container{
      border: 1px solid var(--color-negative) !important;
    }
  }
}